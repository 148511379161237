import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useResourcesContext } from '../resource/ResourceContext';
import IconButton from '@mui/material/IconButton';
import Profile from './Profile';
import { useTranslation } from 'react-i18next';
import * as authService from '../auth/authService'
import * as userService from '../user/userService'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { Tooltip } from '@mui/material';
import * as dashboardService from '../dashboard/dashboardService'

export default function AppMenu(props) {
  const { children } = props;
  const { t } = useTranslation();
  const { componentTitle, selectedMenuItem, loggedInUserInfo, collapse, setCollapse, isWebViews, isMobileView} = useResourcesContext();
  const leftMenuItems = [];
  const isLogin = loggedInUserInfo?.id !== null
  const hasCustomDashboardViewRight = authService.hasModuleViewRight(loggedInUserInfo, authService.modules.CustomDashboards);

  const [favoriteDashboards, setFavoriteDashboards] = useState([]);
  const [isHoveringDashboard, setIsHoveringDashboard] = useState(false);

  useEffect(() => {
    const fetchFavoriteDashboards = async () => {
      try {
        const dashboards = await dashboardService.dashboardMenuItems();
        setFavoriteDashboards(dashboards);
      } catch (error) {
        console.error(error);
      }
    };
    if (hasCustomDashboardViewRight && isHoveringDashboard) {
      fetchFavoriteDashboards();
    }
  }, [isHoveringDashboard]);

  const handleMouseEnter = () => setIsHoveringDashboard(true);
  const handleMouseLeave = () => setIsHoveringDashboard(false);

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Dashboard),
    selected: selectedMenuItem === 'dashboard',
    label: t('dashboard_menu'),
    path: '/dashboard/',
    image: 'dashboard-stacked-icon.png',
    bgColor: '#1d252c',
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Reschedule),
    selected: selectedMenuItem === 'reschedule',
    label: t('reschedule'),
    path: '/reschedule/',
    image: 'kpi-stacked-icon.png',
    bgColor: '#9e9e9eb2'
  });

  const hasWorkOrders = authService.hasAccessRight(loggedInUserInfo, authService.accessRights.WorkOrder);

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Plans),
    selected: selectedMenuItem === 'schedules',
    label: hasWorkOrders ? t('scheduling') : t('agenda'),
    path: hasWorkOrders ? '/schedules/' : '/plans/',
    image: 'agenda-stacked-icon.png',
    bgColor: '#ba68c8'
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Tasks),
    selected: selectedMenuItem === 'tasks',
    label: t('tasks'),
    path: '/tasks/',
    image: 'completed-tasks.png',
    bgColor: '#607d8b'
  });

  const hasVisits = authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Visits);
  const hasJobs = authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Jobs);

  leftMenuItems.push({
    visible: hasVisits || hasJobs,
    selected: selectedMenuItem === 'jobs',
    label: authService.isService(loggedInUserInfo) ? t('jobs') : t('visits'),
    path: '/jobs/',
    image: 'visits-stacked-icon.png',
    bgColor: '#009587'
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Activities),
    selected: selectedMenuItem === 'activities',
    label: t('activities'),
    path: '/activities/',
    image: 'custom-activities-stacked-icon.png',
    bgColor: '#a52a2a'
  });

  const hasCustomActivityAccess = authService.hasAccessRight(loggedInUserInfo, authService.accessRights.CustomActivity);

  leftMenuItems.push({
    visible: hasCustomActivityAccess && authService.hasModuleViewRight(loggedInUserInfo, authService.modules.CustomForms),
    selected: selectedMenuItem === 'form',
    label: t('forms'),
    path: '/form/',
    image: 'custom-form-activities-icon.png',
    bgColor: '#4fc3f7',
    fieldPie2: true
  });

  const hasDealerNetworkViewRight = authService.hasModuleViewRight(loggedInUserInfo, authService.accessRights.DealerNetwork);

  leftMenuItems.push({
    visible: hasDealerNetworkViewRight,
    selected: selectedMenuItem === 'dealernetwork',
    label: t('dealer_network'),
    path: '/dealernetworks',
    image: 'custom-form-activities-icon.png',
    bgColor: '#4fc3f7',
    fieldPie2: true
  });

  const hasDistributorNetworkViewRight = authService.hasModuleViewRight(loggedInUserInfo, authService.accessRights.DistributorNetwork);

  leftMenuItems.push({
    visible: hasDistributorNetworkViewRight,
    selected: selectedMenuItem === 'distributornetwork',
    label: t('distributor_network'),
    path: '/distributornetworks',
    image: 'custom-form-activities-icon.png',
    bgColor: '#4fc3f7',
    fieldPie2: true
  });

  const hasInvoices = authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Invoices);

  leftMenuItems.push({
    visible: hasInvoices,
    selected: selectedMenuItem === 'invoices',
    label: t('invoices'),
    path: '/invoices/',
    image: 'invoice.png',
    bgColor: '#079607'
  });

  const hasQuoteAccess = authService.hasAccessRight(loggedInUserInfo, authService.accessRights.PurchaseOrder);

  leftMenuItems.push({
    visible: hasQuoteAccess && hasInvoices && authService.isService(loggedInUserInfo),
    selected: selectedMenuItem === 'quotes',
    label: t('quotes'),
    path: '/quotes/',
    image: 'custom-activities-stacked-icon.png',
    bgColor: '#079607'
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Clients),
    selected: selectedMenuItem === 'clients',
    label: loggedInUserInfo?.clientNamePlural,
    path: '/clients/',
    image: 'clients-stacked-icon.png',
    bgColor: '#ff9800'
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Users),
    selected: selectedMenuItem === 'users',
    label: t('users'),
    path: '/user/',
    image: 'field-force-stacked-icon.png',
    bgColor: '#03a9f4'
  });

  const hasProducts = authService.hasAccessRight(loggedInUserInfo, authService.accessRights.Product) &&
    authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Products);

  leftMenuItems.push({
    visible: hasProducts,
    selected: selectedMenuItem === 'products',
    label: t('products'),
    path: '/products/',
    image: 'products-stacked-icon.png',
    bgColor: '#ff5722'
  });

  const hasProductsV2 = authService.hasAccessRight(loggedInUserInfo, authService.accessRights.ProductV2) &&
    authService.hasModuleViewRight(loggedInUserInfo, authService.modules.ProductsV2);

  leftMenuItems.push({
    visible: hasProductsV2,
    selected: selectedMenuItem === 'productsV2',
    label: t('products'),
    path: '/productsv2/',
    image: 'products-stacked-icon.png',
    bgColor: '#ff5722',
    fieldPie2: true
  });

  leftMenuItems.push({
    visible: authService.hasModuleEditRight(loggedInUserInfo, authService.modules.PredictFields),
    selected: selectedMenuItem === 'predict',
    label: t('addAIPower'),
    path: '/customforms/?addAIPower=true',
    image: 'stars.png',
    bgColor: '#a52a2a'
  });

  const hasExternalSchedules = authService.hasModuleViewRight(loggedInUserInfo, authService.modules.ExternalSchedules);
  const is123BT = authService.is123BT(loggedInUserInfo);

  leftMenuItems.push({
    visible: hasExternalSchedules,
    selected: selectedMenuItem === 'clientjobrequests',
    label: is123BT ? t('externalSchedules') : t('clientJobRequests_parameter', { clientNameSingular: loggedInUserInfo?.clientNameSingular }),
    path: is123BT ? '/externalschedules/' : '/clientjobrequests/',
    image: 'visits-stacked-icon.png',
    bgColor: '#a52a2a'
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Documents),
    selected: selectedMenuItem === 'documents',
    label: t('documents'),
    path: '/documents/',
    image: 'file-document.png',
    bgColor: '#b84343'
  });

  leftMenuItems.push({
    visible: authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Inventory) || authService.hasModuleViewRight(loggedInUserInfo, authService.modules.InventoryAssingedItems),
    selected: selectedMenuItem === 'inventorylist',
    label: t('inventory_management'),
    path: '/inventorylist/',
    image: 'custom-activities-stacked-icon.png',
    bgColor: '#ff5722'
  });

  const drawerWidth = 240;
  const openedMixin = (theme) => ({
    width: drawerWidth,
    border: 'none',
    transition: theme.transitions.create('width', { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }),
    overflowX: 'hidden'
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    border: 'none',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: { width: `calc(${theme.spacing(8)} + 1px)` }
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }));

  const toggleMenu = async () => {
    const newMenuState = !collapse;
    setCollapse(newMenuState);

    try {
      await userService.updateMenuClosed({ menuClosed: newMenuState });
      localStorage.setItem('menuClosed', newMenuState);
    } catch (e) {
      console.error(e);
    }
  };

  const toggleSidebar = () => {
    if (!collapse) setCollapse(true)
    const toogle = document.querySelector('.toogle');
    toogle.classList.add('hidden');
    const sidebar = document.querySelector('.sidebar');
    const children = document.querySelector('.children');
    const navbarHeader = document.querySelector('.navbarHeader');
    const navbarBrand4 = document.querySelector('.navbar-brand4');
    const navbarBrand5 = document.querySelector('.navbar-brand5');
    if (sidebar.classList.contains('open')) {
      navbarHeader.classList.add('hidden');
      // Close sidebar
      children.classList.remove('open');
      sidebar.classList.remove('open');
      navbarBrand4.classList.add('hidden');
      navbarBrand4.classList.remove('open');
      navbarBrand5.classList.remove('hidden');
      navbarBrand5.classList.add('open');
    } else {
      children.classList.add('open');
      navbarHeader.classList.add('hidden');
      // Open sidebar
      sidebar.classList.add('open');
      navbarBrand4.classList.add('open');
      navbarBrand4.classList.remove('hidden');
      navbarBrand5.classList.remove('open');
      navbarBrand5.classList.add('hidden');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
       <CssBaseline />
      <Box sx={{ display: isWebViews ? 'none' : 'flex', backgroundColor: 'primary.main', minHeight: '64px', height: '64px', justifyContent: 'space-between', alignItems: 'center' }}>
            <div onClick={toggleSidebar} style={{ position: 'fixed', left: 25 }}>
              <a className='navbar-brand4' href="#">
                <img alt="up arrow" src="../../img/up-arrow.png" />
              </a>
              <a className= 'navbar-brand5' href="#">
                <img alt="down arrow" src="../../img/down-arrow-grey.png" />
              </a>
          </div>
        <Box sx={{ mr: collapse ? 15 : 28 }}>
        </Box>
        <Box sx={{ color: '#ffffffd6', fontSize: 22, ml: -5, fontWeight: 500 }}>
          { componentTitle }
        </Box>
        <Profile />
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', height: 1 }}>

        {isLogin && <Drawer style={{ display: isWebViews ? 'none' : null }} className= 'sidebar' variant="permanent" open={!collapse} >
          <DrawerHeader className='navbarHeader' sx={{ backgroundColor: 'primary.main', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', minHeight: '64px', height: '64px' }}>
              {collapse ? <img alt="navbar brand" className='navbar-brand' style={{ width: 41, height: 41, marginLeft: 3 }} src='../../img/miniLogo.png' /> : <img alt="navbar brand" className='navbar-brand' style={{ marginLeft: 14 }} src='../../img/logo.png' />}
          </DrawerHeader>
            <Box sx={{ backgroundColor: 'white', width: '280px', boxShadow: '0 0 6px rgba(0, 0, 0, 0.12)', height: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <Box sx={{ mt: 1.9 }} />
              {leftMenuItems.filter(leftMenuItem => leftMenuItem.visible).map((leftMenuItem, index) => (
                <div
                  key={leftMenuItem.path}
                  onMouseEnter={leftMenuItem.label === t('dashboard_menu') ? handleMouseEnter : undefined}
                  onMouseLeave={leftMenuItem.label === t('dashboard_menu') ? handleMouseLeave : undefined}
                  style={{
                    position: 'relative',
                    textDecoration: 'none',
                    backgroundColor: leftMenuItem.selected ? 'rgba(236, 239, 241, 0.5)' : '#fff0',
                    borderColor: 'rgb(229, 229, 229)',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: leftMenuItem.selected ? '1px' : '0px'
                  }}>
                  <a href={`${leftMenuItem.fieldPie2 ? '' : process.env.REACT_APP_FIELDPIE_OLD_URL}${leftMenuItem.path}`}
                    style={{
                      display: 'block',
                      width: '100%',
                      height: '100%'
                    }}>
                    <Tooltip arrow={true} title={collapse && leftMenuItem.label} placement={index === 0 ? 'bottom' : 'top' } style={{ float: 'left' }} >
                      <Box sx={{ height: '55px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{ ml: collapse ? 1.4 : 2, mr: 2.5, bgcolor: leftMenuItem.bgColor, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img alt={leftMenuItem.label} src={`../../img/${leftMenuItem.image}`} style={{ marginLeft: leftMenuItem.path === '/tasks/' ? '3px' : '0' }}/>
                        </Box>
                        {<Box sx={{ fontSize: 14, color: leftMenuItem.selected ? 'rgb(29, 37, 44)' : '#455a64cc', fontWeight: '400' }}>
                          {!collapse && leftMenuItem.label}
                        </Box>}
                      </Box>
                    </Tooltip>
                  </a>
                  {leftMenuItem.label === t('dashboard_menu') && hasCustomDashboardViewRight && isHoveringDashboard && (
                    <FavoriteDashboards dashboards={favoriteDashboards} collapse={collapse} />
                  )}
                </div>
              ))}
            </Box>
            <IconButton className='toogle' onClick={toggleMenu}>
              {collapse ? <ChevronRightIcon style={{ height: '24px', width: '24px' }} /> : <ChevronLeftIcon style={{ height: '24px', width: '24px' }}/>}
            </IconButton>
          </Drawer>
        }
      <Box className={isWebViews ? 'mobile-children' : 'children'} sx={{ flexGrow: 1, height: isMobileView ? '99%' : 1, overflow: 'auto' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

function FavoriteDashboards({ dashboards, collapse }) {
  const [hovered, setHovered] = useState(null);
  const { t } = useTranslation();

  return (
    <div style={{
      position: 'fixed',
      top: '78px',
      left: collapse ? '65px' : '240px',
      backgroundColor: '#fff',
      boxShadow: '0px 0px 8px rgba(0,0,0,0.1)',
      zIndex: 100,
      maxWidth: '1000px',
      minWidth: '200px',
      maxHeight: '590px',
      overflowY: 'auto'
    }}>
      {dashboards.map((dashboard, index) => {
        if (index === 0) {
          return (
            <div key={dashboard.id} style={{
              paddingRight: '10px',
              paddingLeft: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              textDecoration: 'none',
              color: '#009587',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'default',
              borderBottom: dashboards.length - 1 === index ? 'none' : '1px solid #eee'
            }}
            onMouseEnter={() => setHovered(dashboard.id)}
            onMouseLeave={() => setHovered(null)}>
              {t(`${dashboard.name}`)}
            </div>
          );
        } else {
          return (
            <a key={dashboard.id} href={`/dashboard/${dashboard.id}`} style={{
              display: 'block',
              paddingRight: '10px',
              paddingLeft: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              textDecoration: 'none',
              color: '#333',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              borderBottom: dashboards.length - 1 === index ? 'none' : '1px solid #eee',
              backgroundColor: hovered === dashboard.id ? '#f5f5f5' : 'transparent'
            }}
            onMouseEnter={() => setHovered(dashboard.id)}
            onMouseLeave={() => setHovered(null)}>
              {dashboard.name}
            </a>
          );
        }
      })}
    </div>
  );
}

AppMenu.propTypes = {
  children: PropTypes.object
}
