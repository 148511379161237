import React from 'react';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import CenteredFlexBox from '../components/CenteredFlexBox';
import { useTranslation } from 'react-i18next';

export function Logout() {
  const { t } = useTranslation();

  return (
    <CenteredFlexBox>
      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: 1,
        m: 1,
        width: 1,
        maxWidth: 'sm',
        borderRadius: 1
      }}>
        <CardContent sx={{ p: 1, pt: 0 }}>
          <CenteredFlexBox>
            {t('logging_out')}
          </CenteredFlexBox>
        </CardContent>
      </Card>
    </CenteredFlexBox>
  );
}

export default Logout;
