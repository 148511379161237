import React from 'react';
import { useRouteError } from 'react-router-dom';

export default function RoutingErrorFallback() {
  // TODO geliştirilmeli, geçici halde. Loglamalar merkezi bir yere yapılmalı
  const error = useRouteError();
  console.error(error);

  return (
    <div>
      <p>Something went wrong while routing:</p>
      <pre style={{ color: 'red' }}>{error.data}</pre>
    </div>
  );
}
