const BLOB_STORAGE_URL = process.env.REACT_APP_BLOB_STORAGE_URL;

export const containers = {
  userPhoto: 'userphoto'
}

export function buildPath(container, path) {
  if (!container || !path || container.length <= 0 || path.length <= 0) {
    return null;
  }

  return `${BLOB_STORAGE_URL}/${container}/${path}`;
}
