import React from 'react'
import { NavLink } from 'react-router-dom';

function Menu() {
  return (
    <>
        <div>
            <NavLink to="/login">Login</NavLink>
        </div>
        <div>
            <NavLink to="/subscriber_settings">Subscriber Settings</NavLink>
        </div>
        <div>
            <NavLink to="/dashboards">Dashboards</NavLink>
        </div>
    </>
  );
}

export default Menu;
