/* eslint-disable operator-linebreak */
import { produce } from 'immer';

export default function convert(object, removeBlankStringFields) {
  const convertedObject = produce(object, draft => {
    Object.keys(draft).forEach(key => {
      if (removeBlankStringFields
          && !draft[key]
          && draft[key] !== undefined
          && draft[key] === '') {
        delete draft[key];
        return;
      }

      const isParamTypeObject = typeof draft[key] === 'object';
      const isParamTypeArray = isParamTypeObject && draft[key].length > 0;

      if (isParamTypeArray) {
        draft[key] = String(draft[key]);
      }
    });
  });

  return convertedObject;
}
