import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  // TODO geliştirilmeli, geçici halde. Loglamalar merkezi bir yere yapılmalı
  console.error(error);

  return (
    <div>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
}
