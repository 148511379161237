import { getRequest } from '../httpclient/httpClient';

const RESOURCE_PATH = 'resource';

export async function multipleResources(types) {
  const config = {
    params: {
      types: String(types)
    }
  };
  const resources = await getRequest(RESOURCE_PATH, config);
  return resources.data;
}

export async function getResource(type) {
  const config = {
    params: {
      types: String([type])
    }
  };
  const resources = await getRequest(RESOURCE_PATH, config);
  return resources.data[type];
}
