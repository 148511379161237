import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/system/Box';

export default function CenteredFlexBox(props) {
  const { sx, ...other } = props;

  return (
    <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', ...sx }} {...other} />
  );
}

CenteredFlexBox.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object
  ])
}
